import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import KeyServiceData from "./keyServiceData";
import SingleKeyService from "./SingleKeyService";

const KeyServices = ({ container = "fluid" }) => {
  const KeyServiceArea = () => {
    return (
      <div className="keyServiceArea">
        <ul className="keyServiceUl">
          {KeyServiceData.map((item) => {
            return (
              <li key={item.key}>
                <SingleKeyService items={item} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <section className="section-padding">
      <Container>
        <Row>
          <Col md={12}>
            <div className="section-heading text-center">
              <h2>Our Key Services</h2>
              {container === "default" ? (
                <>
                  <p className="full-width">
                    The Technocrom SQA team is dedicated to quality and strives
                    to transform it from being a bottleneck to a bottom line for
                    companies. With certified resources and expertise in
                    cutting-edge technologies, our team works independently and
                    uses innovative approaches to achieve the best results. We
                    personalize each project beyond the academic approach of
                    quality assurance, drawing from our exposure to diverse
                    industry environments and technologies.
                  </p>
                  <p className="full-width">
                    In addition to our SQA services, our New Product Development
                    services help clients stay relevant and in-demand in the
                    market. Our streamlined process takes clients through the
                    steps involved in NPD, from ideation to product marketing.
                    We guide our clients through the typical twists and turns of
                    the industrial NPD journey, whether they are technical,
                    bureaucratic, or social, making each step easier and safer
                    for businesses venturing into NPD.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    At our company, we offer a wide range of quality assurance
                    services, including testing for both mobile and web
                    applications. Our team of experts can provide comprehensive
                    test advisory services and conduct security testing to
                    ensure that your applications are safe and secure. In
                    addition to testing, we also provide web and mobile
                    application development services. Our skilled professionals
                    can help you build a high-quality, customized application
                    that meets your unique business needs. You can trust us to
                    deliver top-notch quality and reliability in all of our
                    services.
                  </p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {container === "default" ? (
        <Container className="inner-key-services">
          <KeyServiceArea />
        </Container>
      ) : (
        <KeyServiceArea />
      )}
    </section>
  );
};

export default KeyServices;
