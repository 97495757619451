import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const TwinSection = () => {
  return (
    <>
      <section className="twin-section" id="team-augmentation-model">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Team Augmentation Model</h2>
                <p>
                  Team augmentation is a strategy that many companies and
                  organizations use to bring in specialized talent to supplement
                  their existing teams. This approach can be an effective way to
                  manage resources and achieve specific goals.
                </p>
                <p>
                  One of the biggest benefits of team augmentation is the
                  ability to add specialized skills and expertise to existing
                  teams quickly. Companies can hire employees with specific
                  skills or experience to enhance their current teams,
                  particularly when working on new projects or facing a shortage
                  of resources. Instead of going through the time-consuming and
                  costly process of hiring new full-time employees, team
                  augmentation provides companies with a flexible option to add
                  the necessary talent and expertise.
                </p>
                <p>
                  Another advantage of team augmentation is the flexibility it
                  offers companies. Organizations can scale their teams up or
                  down depending on their workload and project requirements,
                  which can be particularly useful when there are fluctuations
                  in demand or a specific deadline to meet.
                </p>
                <p>
                  Team augmentation can also be beneficial for employees, as it
                  provides them with the opportunity to work on diverse projects
                  and gain a range of experience. This is particularly
                  attractive for individuals who enjoy working in a team
                  environment and want to enhance their skills and expertise.
                </p>
                <p>
                  However, one of the biggest challenges of team augmentation is
                  the potential for a lack of continuity. As employees work on
                  different projects and teams at different times, it can be
                  difficult to build relationships and establish trust with
                  their colleagues. In addition, team augmentation requires
                  significant coordination and communication between the
                  augmentation team and the existing team to ensure a smooth
                  integration.
                </p>
                <p>
                  Despite these potential downsides, team augmentation can be an
                  effective strategy for companies and employees alike. It
                  provides flexibility, access to specialized talent, and
                  opportunities to work on diverse projects. However, it is
                  essential for both companies and employees to be aware of the
                  potential challenges and work together to ensure effective
                  communication and coordination.
                </p>

                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/engagement/twin-section.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default TwinSection;
