import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const Banking = () => {
  return (
    <>
      <InnerBanner title="Banking" />
      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Banking</h2>
                <p>
                  Automation testing can also be utilized to improve the
                  efficiency and accuracy of testing processes in banking.
                  Automation can help save time and resources by allowing QA
                  teams to run tests more quickly and efficiently, and also
                  increase the coverage of testing. However, it's important to
                  note that automation testing should not replace manual testing
                  entirely, as some testing scenarios may require the human
                  touch and intuition.
                </p>

                <Row className="lifeAtTechno-boxes justify-content-center">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>
                          Ensuring accuracy and reliability of financial
                          transactions:
                        </strong>{" "}
                        QA teams can test and verify the accuracy of financial
                        transactions, such as deposits, withdrawals, and
                        transfers, to ensure that they are processed correctly
                        and without errors.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>
                          Protecting against fraud and security breaches:
                        </strong>{" "}
                        QA teams can test and verify the security of banking
                        systems and processes, such as online banking and mobile
                        banking, to protect against fraud and security breaches.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>
                          Maintaining compliance with regulations:
                        </strong>{" "}
                        QA teams can test and verify that banking systems and
                        processes comply with regulations, such as the Payment
                        Card Industry Data Security Standards (PCI DSS) and the
                        General Data Protection Regulation (GDPR)
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>Improving customer experience:</strong> QA teams
                        can test and verify the usability and accessibility of
                        banking systems and processes to ensure that they are
                        easy to use and provide a positive customer experience.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>Identifying and fixing issues quickly:</strong>{" "}
                        QA teams can identify and fix issues quickly, which can
                        help prevent disruptions to banking services and
                        minimize the impact of any problems.
                      </p>
                    </div>
                  </Col>
                </Row>

                <p>
                  Furthermore, QA teams can also play a role in improving the
                  overall digital transformation of the banking industry. With
                  the increasing use of technology and digital solutions in
                  banking, QA can ensure that these systems and processes are
                  integrated seamlessly and function efficiently, without
                  compromising the security and accuracy of financial
                  transactions and customer data.
                </p>
                <p>
                  In summary, QA is essential in the banking sector for ensuring
                  the accuracy, reliability, and security of banking systems and
                  processes, as well as improving the customer experience. To be
                  effective, QA teams need to have a deep understanding of the
                  banking industry, relevant regulations and standards, and
                  utilize a robust test management system along with automation
                  testing where appropriate.
                </p>

                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/banking1.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default Banking;
