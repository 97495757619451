import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import KeyExpertiseData from "./KeyExpertiseData";
import SingleKeyExpertise from "./SingleKeyExpertise";

const KeyExpertise = ({ container = "fluid" }) => {
  const KeyExpertiseArea = () => {
    return (
      <div className="keyServiceArea">
        <ul className="keyServiceUl">
          {KeyExpertiseData.map((item) => {
            return (
              <li key={item.key}>
                <SingleKeyExpertise items={item} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <section className="section-padding">
      <Container>
        <Row>
          <Col md={12}>
            <div className="section-heading text-center">
              <h2>Our Key Expertise</h2>
              <p className="full-width">
                Technocrom SQA is dedicated to quality in all aspects of
                operations and projects. Our team of certified professionals is
                independent and well-versed in cutting-edge technologies. We
                strive to transform quality from a bottleneck to a bottom-line
                driver for companies. Our approach to quality assurance is
                personal and innovative, drawing on diverse industry
                environments and technologies.
              </p>
              <p className="full-width">
                Our New Product Development services provide a strategic
                solution to staying relevant and in-demand in the market. Our
                streamlined process guides businesses through every step of NPD,
                from ideation to product marketing. We help our clients navigate
                the typical challenges of the industrial NPD journey, whether
                they be technical, bureaucratic, or social. With our guidance,
                businesses can safely and confidently venture into new product
                development.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {container === "default" ? (
        <Container className="inner-key-services">
          <KeyExpertiseArea />
        </Container>
      ) : (
        <KeyExpertiseArea />
      )}
    </section>
  );
};

export default KeyExpertise;
