import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const SecurityTesting = () => {
  return (
    <>
      <InnerBanner title="Security Testing" />
      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Mitigating Global Cybersecurity Risks</h2>
                <p>
                  The rise of cybercrimes has become a major concern for
                  businesses worldwide. In 2021, it was reported that
                  cybercrimes have increased by 600% globally, with hackers
                  compromising the systems of approximately 67% of companies
                  based in the United States. The World Economic Forum's Global
                  Risks 2015 report states that 90% of companies worldwide
                  recognize they are insufficiently prepared to protect
                  themselves against cyber-attacks, which could cost the global
                  economy over a trillion dollars annually.
                </p>
                <p>
                  To counter this threat, it's essential to make product
                  security a key factor in both the design and development
                  phases. SQA team's security testing services ensure secure
                  data transfer between connected systems by verifying
                  end-to-end network security of applications as per different
                  security standards. Our team of security professionals
                  performs a thorough threat analysis of your network
                  environment, identifying gaps and vulnerabilities, and
                  providing a comprehensive security profile of your products.
                </p>
                <p className="mb-0">Our security services include:</p>

                <Row className="lifeAtTechno-boxes justify-content-center mt-0">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Security Testing - Identifying security vulnerabilities
                        in your software, network, and systems.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Risk Assessment - Assessing security risks for your
                        software, network, and systems.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Compliance Testing - Ensuring compliance with security
                        standards and regulations.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Security Audit - Providing a comprehensive security
                        audit of your software, network, and systems.
                      </p>
                    </div>
                  </Col>
                </Row>
                <p>
                  By utilizing our security services, you can rest assured that
                  your products are secure and your data is protected from any
                  security threats.
                </p>

                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/security-testing.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default SecurityTesting;
