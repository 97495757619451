import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CaseStudyCarousel from "./CaseStudyCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const CaseStudyText = () => {
  return (
    <>
      <h2>Health Insurance Industry</h2>
      <p>
        Health insurance serves as a crucial financial link between patients,
        healthcare providers, and insurance providers, creating a system that
        benefits all parties. The healthcare providers and insurance providers
        act as producers, while the patients are the consumers. When a patient
        requires clinical services, they submit a request to their healthcare
        provider, who offers the necessary facilities. The provider then bills
        the insurance provider for the services rendered. To keep track of these
        transactions, providers use portals to record the services provided to
        patients, and insurance providers use the same portals to process and
        view these transactions.
      </p>
    </>
  );
};

const CaseStudy = () => {
  const handleSelected = (event) => {
    let index = event.page.index;
    index = index < 0 ? 0 : index;
    console.log(index);
  };

  return (
    <>
      <section className="section-padding case-study-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-heading text-center">
                <h2>Case Studies & Stories</h2>
                <p>
                  We welcome you to explore our collection of case studies, in
                  which we conduct in-depth investigations and offer detailed
                  accounts of various incidents. Our comprehensive approach
                  includes analyzing the background and providing an overall
                  analysis of each case, enabling you to gain valuable insights
                  into the subject matter.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="case-study-carousel">
                <CaseStudyCarousel handleSelected={handleSelected} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="section-heading text-center case-study-bottomText">
                <CaseStudyText />
                <div className="link-area">
                  <Link to="/case-studies" className="default-blue">
                    See All Stories <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CaseStudy;
