import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const BusinessIntelligence = () => {
  return (
    <>
      <InnerBanner title="Business Intelligence" />
      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>
                  Empower Your Business with Actionable Insights: Business
                  Intelligence Solutions
                </h2>
                <p>
                  Unlock the true potential of your data and gain valuable
                  insights to drive informed decision-making. At Technocrom, we
                  offer comprehensive business intelligence solutions designed
                  to transform raw data into actionable information. With our
                  cutting-edge analytics tools and expertise, you can optimize
                  operations, improve performance, and stay ahead in today's
                  competitive landscape.
                </p>

                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/bi1.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Why Choose Techncrom for Business Intelligence?</h2>
                <p>
                  Data-driven Decision Making: Make informed decisions based on
                  accurate, real-time data. Our business intelligence solutions
                  help you harness the power of your data, providing you with
                  actionable insights that drive strategic initiatives and boost
                  performance.
                </p>

                <Row className="lifeAtTechno-boxes justify-content-center">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Comprehensive Analytics:</b> From data collection and
                        integration to advanced analytics and visualization, our
                        solutions cover the entire spectrum of business
                        intelligence. We help you uncover trends, patterns, and
                        correlations in your data, enabling you to identify
                        opportunities, mitigate risks, and optimize processes.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Customized Solutions:</b> We understand that every
                        business has unique needs. Our team works closely with
                        you to understand your goals, challenges, and specific
                        requirements. We then tailor our business intelligence
                        solutions to align with your objectives, ensuring
                        maximum impact and ROI.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Advanced Tools and Technologies:</b> Stay ahead with
                        our state-of-the-art tools and technologies. We leverage
                        advanced analytics platforms, machine learning
                        algorithms, and data visualization tools to deliver
                        sophisticated and user-friendly solutions that empower
                        your teams to make data-driven decisions with ease.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Data Security and Privacy:</b> We prioritize the
                        security and privacy of your data. Our business
                        intelligence solutions adhere to industry best practices
                        and compliance standards, ensuring your data remains
                        protected and confidential throughout the entire process
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/bi2.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Our Business Intelligence Process</h2>

                <Row className="lifeAtTechno-boxes justify-content-center">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Data Assessment and Integration:</b> We conduct a
                        thorough assessment of your existing data landscape,
                        identifying relevant data sources and ensuring data
                        integrity. We then integrate disparate data sets into a
                        centralized data warehouse or data lake for a unified
                        view.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Data Cleansing and Transformation:</b> We cleanse and
                        transform your data, ensuring accuracy and consistency.
                        We handle data normalization, de-duplication, and data
                        enrichment to enhance data quality and make it suitable
                        for analysis.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Analytics and Insights:</b> Using advanced analytics
                        techniques, we analyze your data to uncover meaningful
                        insights and trends. We employ statistical modeling,
                        data mining, and predictive analytics to identify
                        patterns, correlations, and future projections.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Data Visualization and Reporting:</b> We present the
                        findings in visually appealing and interactive
                        dashboards and reports. Our data visualization tools
                        provide intuitive representations of complex data,
                        enabling stakeholders to grasp insights quickly and make
                        data-driven decisions.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Continuous Monitoring and Optimization:</b> We
                        believe in ongoing improvement. We monitor the
                        performance of your business intelligence solution,
                        provide regular updates, and optimize it based on
                        changing business needs and emerging trends.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/bi4.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Unlock the Power of Your Data Today</h2>
                <p>
                  Experience the transformative potential of business
                  intelligence with Technocrom. Our solutions empower businesses
                  to extract actionable insights from their data, enabling
                  smarter decision-making and driving growth.
                </p>
                <p>
                  Contact us today to learn more about our business intelligence
                  services and how we can help your organization harness the
                  power of data for success.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/bi3.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default BusinessIntelligence;
