import { faArrowRight, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const DigitalTesting = () => {
  return (
    <>
      <InnerBanner title="Digital Testing" />
      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>
                  End-To-End Services Enablement For Next-Gen Digital
                  Applications
                </h2>
                <h5>Embrace Digital Testing For Your Agile And DevOps</h5>
                <p>
                  In today's fast-paced digital world, businesses are striving
                  to keep up with the ever-growing demands of their customers.
                  Digital technology is evolving rapidly and has brought
                  significant changes to the way enterprises operate. Customers
                  today want all services available to them at the click of a
                  button, making it essential for businesses to provide
                  high-quality digital applications that meet their needs.
                </p>
                <p>
                  At Technocrom, we understand the importance of delivering
                  top-notch digital experiences to customers. We provide
                  end-to-end digital QA services that cater to all your digital
                  testing needs. Our team of experts ensures that your
                  applications meet the highest standards of quality and deliver
                  a seamless experience to your customers.
                </p>
                <p>
                  Our mobile and web app testing services are designed to
                  address various challenges, such as functionality,
                  performance, security, usability, and cross-device
                  compatibility. We work closely with our clients in various
                  methodologies, including Agile, Iterative, or DevOps, to
                  ensure that our digital testing services align with their
                  specific requirements.
                </p>
                <p>
                  By partnering with Technocrom, you can benefit from our
                  experience in delivering high-quality digital applications. We
                  can help you achieve faster time-to-market for your software,
                  which is essential for staying ahead of your competitors. We
                  are committed to delivering the best-in-class digital testing
                  services to our clients and ensuring that their customers have
                  a positive digital experience every time they interact with
                  their applications.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/services" className="default-blue">
                    Explore More <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/digital-testing.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default DigitalTesting;
