import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const HealthCare = () => {
  return (
    <>
      <InnerBanner title="Health Care" />

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Health Care Apps and Hospital Information Systems</h2>
                <p>
                  At Technocrom, we specialize in developing health care apps
                  and hospital information systems. Our team has extensive
                  experience in building management systems for hospitals,
                  clinics, and clinical laboratories. Our systems are designed
                  to include a range of features, such as patient demographics,
                  diagnostic (intelligent) systems, and ward and room allocation
                  management, as well as administration tools.
                </p>
                <p>
                  We have developed a hospital management information system
                  that includes finance, cash counters, billing, account
                  payables, personnel management systems, and systems for
                  fund/charity. Our systems are tailored to meet the specific
                  requirements of each individual client, providing customized
                  solutions that meet the unique needs of their healthcare
                  environment. With our expertise in health care technology, we
                  aim to provide our clients with the tools they need to manage
                  their operations more efficiently, delivering better care to
                  their patients.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/healthcare1.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Our Contribution</h2>
                <p>
                  At Technocrom, we have a proven track record of developing
                  sophisticated internet and intranet applications for leading
                  clients in the insurance, healthcare, and financial services
                  industries. Our team of experienced developers can build
                  custom applications from scratch using industry-standard tools
                  and programming languages. Alternatively, we can tailor
                  existing applications to meet the specific needs and
                  requirements of individual customers and their operations.
                </p>
                <p>
                  We take a customer-centric approach to application
                  development, working closely with our clients to understand
                  their unique business requirements and objectives. Our team
                  leverages the latest development methodologies and
                  technologies to deliver high-quality, scalable, and reliable
                  applications that meet or exceed our clients' expectations.
                </p>
                <p>
                  We pride ourselves on our ability to develop customized
                  solutions that are aligned with our clients' business goals,
                  as well as our commitment to providing exceptional customer
                  service and support throughout the development process and
                  beyond. Whether you need a new application built from scratch
                  or an existing application customized to your needs,
                  Technocrom has the expertise and resources to deliver
                  exceptional results.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/healthcare2.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Web Dimensions</h2>
                <p>
                  Web Dimensions is a highly specialized system developed by
                  Technocrom for the US healthcare industry to facilitate the
                  reporting of workers' First Report of Injury. It was designed
                  to meet the unique requirements of all 51 US states. With this
                  system, users can easily report an injury and view or print
                  reports using a web browser.
                </p>
                <p>
                  The system was developed using Java, XML/XSL, and Servlets
                  technologies, which allows for great flexibility in terms of
                  configuration. The configuration of the database,
                  connectivity, input forms, form validation rules, and more are
                  stored in an XML-based markup language, making it easy to
                  modify and customize the system to fit the specific needs of
                  healthcare providers and/or government agencies.
                </p>
                <p>
                  Technocrom is also developing a range of tools for Web
                  Dimensions to enhance its functionality and make it even more
                  tailored to the specific needs of its users.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/healthcare3.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default HealthCare;
