import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const ProjectBased = () => {
  return (
    <>
      <section className="section-padding" id="project-based">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-heading text-center">
                <h2>Project Based</h2>
                <p className="full-width text-left">
                  Project-based working is a popular model for companies and
                  organizations, as it enables them to bring in specialized
                  talent for specific projects or assignments, rather than
                  hiring full-time employees. This approach offers several
                  advantages, including flexibility, cost-effectiveness, and the
                  ability to access specialized talent.
                </p>
                <p className="full-width text-left">
                  One of the main benefits of project-based working is
                  flexibility. Companies can hire employees on a
                  project-by-project basis, which means they only pay for the
                  resources they need when they need them. This is especially
                  useful for companies that experience fluctuations in workload,
                  as they can easily scale up or down their workforce as needed.
                </p>
                <p className="full-width text-left">
                  Another advantage of project-based working is the ability to
                  access specialized talent. Companies may require employees
                  with specific skills or experience for a particular project,
                  and hiring on a project-by-project basis allows them to find
                  the right people with the necessary expertise. This can be
                  more effective than relying on full-time employees who may not
                  have the required skills or experience.
                </p>
                <p className="full-width text-left">
                  Project-based working also benefits employees by providing
                  opportunities to gain diverse experience and work on a variety
                  of projects. This is especially attractive to individuals who
                  enjoy working on different types of projects and don't want to
                  be tied down to one company or role.
                </p>
                <p className="full-width text-left">
                  However, project-based working can also have its drawbacks,
                  such as the lack of job security it provides, as employees are
                  not guaranteed ongoing work. Project-based workers may also
                  lack access to the same benefits and perks that full-time
                  employees receive, such as health insurance and retirement
                  plans.
                </p>
                <p className="full-width text-left">
                  In summary, project-based working can be a beneficial model
                  for companies and employees, offering flexibility, access to
                  specialized talent, and diverse experiences. However, it's
                  important for both parties to be aware of the potential
                  downsides and plan accordingly.
                </p>
              </div>
            </Col>
            <Col lg={12}>
              <img
                src={require("../../assets/images/engagement/project-based.jpg")}
                alt=""
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ProjectBased;
