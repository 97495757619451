const CaseStudyData = [
  {
    key: 1,
    image: require("../../assets/images/casestudy/1.jpg"),
    title: "Web Development",
    detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    key: 2,
    image: require("../../assets/images/casestudy/2.jpg"),
    title: "Digital Testing",
    detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    key: 3,
    image: require("../../assets/images/casestudy/3.jpg"),
    title: "Test Automation",
    detail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
];

export default CaseStudyData;
