import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const WhyTechnocrom = () => {
  return (
    <>
      <section
        className="twin-section reversed-twin mt-default"
        id="why-technocrom"
      >
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Why Technocrom</h2>
                <p>
                  We believe in discussions! We listen and advise our clients
                  about their projects.
                </p>
                <div className="why-techno-detail">
                  <h3>We listen</h3>
                  <p>
                    We value communication and believe in the power of
                    discussions to understand our clients' projects fully. Our
                    team listens attentively and provides sound advice to ensure
                    successful project outcomes.
                  </p>
                </div>
                <div className="why-techno-detail">
                  <h3>Experience</h3>
                  <p>
                    With over three years of experience in web and application
                    development services, as well as providing top-notch quality
                    assurance services, we offer our clients an all-in-one
                    solution to manage their websites and mobile applications.
                  </p>
                </div>
                <div className="why-techno-detail">
                  <h3>Professionalism</h3>
                  <p>
                    We take pride in our team of highly experienced
                    professionals who bring exceptional expertise to each
                    project.
                  </p>
                </div>
                <div className="why-techno-detail">
                  <h3>Passionate</h3>
                  <p>
                    Our team members are passionate about what they do, and we
                    go the extra mile to provide quality services to our
                    clients, striving to exceed their expectations.
                  </p>
                </div>
                <div className="link-area justify-content-start">
                  <Link to="/how-we-work" className="default-blue">
                    Explore More <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/company/why.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default WhyTechnocrom;
