import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const MissionVision = () => {
  return (
    <>
      <section
        className="section-padding mission-vision-section"
        id="mission-vision"
      >
        <Container className="position-relative">
          <div className="mission-vision-bg"></div>
          <Row className="mv-first">
            <Col md={4}>
              <div className="section-heading">
                <h2>Our Vision</h2>
                <p>
                  Our vision and goal at Technocrom is to embed robust quality
                  assurance practices deeply into the development lifecycle,
                  which allows you to reduce the cost, time, and effort spent on
                  testing year after year.
                </p>
                <p>
                  We help you optimize your test practices to respond
                  effectively to the dynamic market conditions and growing
                  demands. Our team supports you in leveraging industry best
                  practices and accelerators to gain agility and
                  competitiveness. We strive to enable our clients to achieve
                  their business objectives by delivering top-notch quality
                  assurance services that drive cost savings, increased
                  efficiency, and enhanced productivity.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mv-second">
            <Col md={4}>
              <div className="section-heading">
                <h2>Our Mission</h2>
                <p>
                  At our company, we are dedicated to creating sustainable value
                  for our customers by disrupting the status quo and
                  implementing innovative ideas and technologies to meet their
                  QA, testing, and development needs. We remain
                  technology-agnostic, allowing us to concentrate on solving our
                  clients' unique requirements. Our primary focus is on ensuring
                  our customers' satisfaction, which drives our success. We
                  believe in surpassing their expectations, and that belief
                  powers our every action. Our team is committed to delivering
                  top-quality services that exceed our clients' expectations,
                  ensuring that they are delighted with the results.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MissionVision;
