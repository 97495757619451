import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const PointOfSale = () => {
  return (
    <>
      <InnerBanner title="Point Of Sale" />
      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Point Of Sale (POS)</h2>
                <p>
                  A well-designed and well-tested POS system can help businesses
                  increase efficiency, reduce errors, and improve the overall
                  customer experience. It can also provide valuable insights
                  into sales trends, inventory management, and other key
                  metrics, which can inform business decisions and strategies.
                </p>

                <Row className="lifeAtTechno-boxes justify-content-center">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>Testing for functionality:</strong> QA teams can
                        test POS systems to ensure that all features and
                        functions work as intended, including processing
                        transactions, managing inventory, and generating
                        reports.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>Verifying accuracy:</strong> QA teams can test
                        and verify that POS systems accurately process
                        transactions, including calculating taxes and discounts,
                        and that the data is recorded correctly in the system.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>Ensuring reliability:</strong> QA teams can test
                        and verify that POS systems are able to handle
                        high-volume transactions and that they can function
                        continuously without interruption.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>Testing for security:</strong> QA teams can test
                        and verify that POS systems are secure and protect
                        sensitive customer and financial information, including
                        credit card and personal data, from unauthorized access
                        and data breaches.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>Identifying and resolving issues:</strong> QA
                        teams can identify and resolve issues quickly, which can
                        help prevent disruptions to business operations and
                        minimize the impact of any problems.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <strong>User acceptance testing:</strong> QA teams can
                        perform user acceptance testing to ensure that the
                        system is intuitive and easy for employees to use and
                        that it meets the business requirements.
                      </p>
                    </div>
                  </Col>
                </Row>

                <p>
                  At Technocrom, we have extensive experience in developing and
                  testing POS systems for a variety of industries. Our QA teams
                  use a range of tools and techniques, including manual and
                  automated testing, to ensure that the POS systems we develop
                  are of the highest quality. We also work closely with our
                  clients to understand their specific requirements and tailor
                  our testing approach accordingly.
                </p>
                <p>
                  In summary, QA plays a critical role in the development and
                  maintenance of POS systems, ensuring that they are functional,
                  accurate, reliable, and secure. A well-designed and
                  well-tested POS system can provide significant benefits to
                  businesses, including increased efficiency, improved customer
                  experience, and valuable insights into key metrics. At
                  Technocrom, we have the expertise and experience to develop
                  and test high-quality POS systems for our clients.
                </p>

                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/pos1.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default PointOfSale;
