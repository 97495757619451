import React from "react";

const LifeAtTechnocromCarousel = ({ handleSelected }) => {
  return (
    <>
      <img
        src={require("../../assets/images/company/life.jpg")}
        alt=""
        className="lifeAtTechno-img"
      />
    </>
  );
};

export default LifeAtTechnocromCarousel;
