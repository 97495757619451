import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const AppDevelopment = () => {
  return (
    <>
      <InnerBanner title="App Development" />

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Mobile Applications</h2>
                <p>
                  Technocrom offers comprehensive mobile application development
                  services to cater to various mobility needs of enterprises,
                  consumers, and industries. Our services cover a wide range of
                  mobile app development platforms including native (iOS,
                  Android, Windows, WinCE), hybrid, and web-based mobile
                  applications. Our team of experts provides tailor-made
                  services that strike a perfect balance between security,
                  functionality, and user experience, as per the specific
                  requirements of each client.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/mobile-app.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Access Control Solutions</h2>
                <p>
                  Access control is a critical aspect of any enterprise, and
                  Technocrom offers mobile ID apps for access control to help
                  increase security while providing ease of access to authorized
                  personnel. These solutions offer dual benefits to the
                  enterprise, as increased security minimizes losses while ease
                  of access for authorized personnel increases productive time
                  for employees. Our mobile ID app can also be integrated with
                  personnel's performance reports and other time management
                  software to provide an integrated accountability solution.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/mobile-app1.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Productivity Solutions</h2>
                <p>
                  Technocrom offers customized mobile productivity solutions to
                  help businesses enhance their team's performance and
                  streamline their workflow. Our suite of productivity apps
                  includes requirements management, document management, and
                  application life cycle management. These apps are designed to
                  simplify data generation, integration, and analysis, allowing
                  you to make informed strategic decisions. By using our
                  productivity solutions, you can increase your team's
                  efficiency and productivity, which in turn will help you
                  achieve your business goals.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/mobile-app2.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Integrated IoT Applications for Productivity</h2>
                <p>
                  Technocrom's integrated IoT applications for productivity
                  provide seamless connectivity between devices and systems for
                  enhanced productivity. Our services include developing IoT
                  applications from scratch or developing a mobile interface for
                  an existing IoT app. With our modular approach, we can provide
                  customized solutions that meet your specific needs. Our IoT
                  solutions are designed to streamline data collection,
                  analysis, and reporting, allowing you to make informed
                  decisions that drive growth and innovation. With our mobile
                  interface, you can access your IoT applications from anywhere,
                  giving you greater flexibility and control over your business
                  processes.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/mobile-app3.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Customer-Centric Mobile Apps</h2>
                <p>
                  At Technocrom, we design customer-centric mobile apps that
                  capture users' attention with original concepts, appealing
                  aesthetics, and seamless cross-platform performance. Our app
                  development teams leverage the expertise of VR, 3D design, and
                  Unity game development specialists to boost user engagement.
                </p>
                <p>
                  Our portfolio of mobile apps caters to diverse audiences,
                  covering a wide range of themes and categories, including:
                </p>
                <ul>
                  <li>
                    Lifestyle and wellness: apps related to fitness, nutrition,
                    meditation, and mental health
                  </li>
                  <li>
                    Entertainment: gaming, music, video streaming, and social
                    media apps
                  </li>
                  <li>
                    E-commerce: apps for online shopping, payment, and delivery
                    services
                  </li>
                  <li>
                    Travel and tourism: apps for booking flights, hotels, and
                    travel guides
                  </li>
                  <li>
                    Education and learning: apps for online courses, language
                    learning, and educational games
                  </li>
                  <li>
                    Productivity and organization: apps for task management,
                    note-taking, and time tracking
                  </li>
                  <li>
                    Health and medical: apps for monitoring health conditions,
                    medication tracking, and telemedicine services
                  </li>
                  <li>
                    Real estate: apps for property search, buying, and renting
                  </li>
                  <li>
                    Food and beverage: apps for food delivery, recipe sharing,
                    and restaurant discovery
                  </li>
                  <li>
                    Transportation: apps for ride-hailing, public transport
                    schedules, and parking information.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/mobile-app4.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default AppDevelopment;
