const KeyExpertiseData = [
  {
    key: 1,
    title: "Health Care",
    link: "/health-care",
    desc: "At Technocrom, we specialize in developing health care apps and hospital information systems. Our team has extensive experience in building management systems for hospitals, clinics, and clinical laboratories. Our systems are designed to include a range of features, such as patient demographics, diagnostic (intelligent) systems, and ward and room allocation management, as well as administration tools.",
    image: require("../../assets/images/keyService/1.jpg"),
  },
  {
    key: 2,
    title: "Point Of Sale (POS)",
    link: "/point-of-sale",
    desc: "A well-designed and well-tested POS system can help businesses increase efficiency, reduce errors, and improve the overall customer experience. It can also provide valuable insights into sales trends, inventory management, and other key metrics, which can inform business decisions and strategies.",
    image: require("../../assets/images/keyService/2.jpg"),
  },
  {
    key: 3,
    title: "Business Intelligence",
    link: "/business-intelligence",
    desc: "Quality assurance (QA) plays a crucial role in the banking sector, as it helps ensure that the systems, processes, and services provided by banks are accurate, reliable, and secure. QA can be effective in the banking sector in the following ways:",
    image: require("../../assets/images/keyService/3.jpg"),
  },
  {
    key: 4,
    title: "Banking",
    link: "/banking",
    desc: "Automation testing can also be utilized to improve the efficiency and accuracy of testing processes in banking. Automation can help save time and resources by allowing QA teams to run tests more quickly and efficiently, and also increase the coverage of testing. However, it's important to note that automation testing should not replace manual testing entirely, as some testing scenarios may require the human touch and intuition.",
    image: require("../../assets/images/keyService/4.jpg"),
  },
  {
    key: 5,
    title: "E-Commerce",
    link: "/ecommerce",
    desc: "Creating a secure B2B e-commerce website is crucial for businesses looking to expand their reach globally. Technocrom's expertise in creating robust storefront model websites can help businesses establish a strong online presence and streamline their e-commerce operations.",
    image: require("../../assets/images/keyService/5.jpg"),
  },
  {
    key: 6,
    title: "Gaming Apps",
    link: "/gaming-apps",
    desc: "Adobe Enterprise Manager (AEM) is a powerful tool for building and managing websites, mobile apps, and other digital experiences. With its integration with other Adobe products, such as Adobe Analytics, Adobe Target, and Adobe Campaign, AEM provides a comprehensive solution for managing all aspects of digital marketing.",
    image: require("../../assets/images/keyService/6.jpg"),
  },
];

export default KeyExpertiseData;
