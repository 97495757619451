import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const ManualTesting = () => {
  return (
    <>
      <InnerBanner title="Manual Testing" />

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Manual Testing</h2>
                <p>
                  Manual testing is a crucial step in ensuring the quality of an
                  application. At Technocrom SQA, we understand the importance
                  of manual testing and offer a comprehensive range of services
                  to meet the needs of our clients. Our process begins with a
                  thorough analysis of the requirements to create a test plan
                  that outlines the manual and automated testing needed for the
                  application.
                </p>
                <p>
                  Our team then identifies the test scenarios and writes the
                  test cases. Regression testing is carried out to ensure that
                  the application quality remains consistent even after fixing
                  the issues. We document all the reports generated during
                  different stages of manual testing through our defect life
                  cycle management process, which provides us with valuable
                  insights into the reasons for bugs and ways to improve the
                  quality of the application for future development cycles.
                </p>
                <p>
                  At Technocrom SQA, we specialize in manual testing for both
                  embedded and web applications. Our team of experts has
                  extensive experience in testing various applications and can
                  quickly identify and report any issues or bugs, ensuring that
                  the application meets the quality standards set by our
                  clients. We believe that manual testing is an essential part
                  of the testing process, and our focus on this aspect of
                  quality assurance sets us apart from our competitors.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/manual-testing.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Testing Web Applications</h2>
                <p>
                  When it comes to testing web applications, ensuring the end
                  user's satisfaction is one of the most critical
                  considerations. To ensure the quality of a web application
                  from the user's perspective, we offer a range of testing
                  services, including functionality testing, usability testing,
                  and user interface testing.
                </p>
                <p>
                  Functional testing for web applications depends on the
                  application's structure. Typically, we conduct functional
                  testing in two steps: front-end functionality verification and
                  back-end functionality verification. Front-end tests involve
                  testing the user interface and the functionality of various
                  controls, while back-end tests focus on verifying web
                  services, databases, XML entries, and other components.
                </p>
                <p>
                  Our usability testing services are designed to ensure that
                  your web application is intuitive and easy to navigate,
                  providing a seamless user experience. Through our user
                  interface testing, we evaluate the overall look and feel of
                  your web application, ensuring that it meets your branding
                  guidelines and is visually appealing to your target audience.
                </p>
                <p>
                  Our team uses a variety of development tools to help us
                  respond quickly to any functional or usability issues,
                  ensuring that your web application meets the required quality
                  and performance standards. We work diligently to identify any
                  potential issues early on in the testing process, allowing us
                  to address them proactively and minimize any potential
                  negative impact on your end-users.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/manual-testing1.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Testing Mobile Applications</h2>
                <p>
                  When it comes to testing mobile applications, we recognize
                  that the functionality of an app installed on a mobile device
                  or tablet is different from that of a PC application.
                  Typically, mobile apps have fewer features and are more
                  performance-oriented, which is why we place a strong emphasis
                  on testing the features of mobile applications against the
                  desired behavior.
                </p>
                <p>
                  One of the most critical aspects of functional testing for
                  mobile applications is testing the app's performance on
                  various mobile devices and tablets. This involves testing the
                  app's performance under different network conditions and
                  verifying that it is compatible with different operating
                  systems and device configurations.
                </p>
                <p>
                  Our team is well-versed in the latest testing methodologies
                  and tools for mobile application testing. We use a combination
                  of manual testing and test automation to ensure comprehensive
                  testing of your mobile application's functionality. We also
                  focus on testing the application's user interface and user
                  experience, ensuring that it is intuitive and easy to navigate
                  for your target audience.
                </p>
                <p>
                  In addition to functional testing, we also offer performance
                  testing for mobile applications. This includes load testing,
                  stress testing, and soak testing, to ensure that your mobile
                  app performs well under various usage scenarios.
                </p>
                <p>
                  Overall, our goal is to help you deliver a high-quality mobile
                  application that meets the expectations of your end-users. We
                  work closely with you throughout the testing process,
                  providing timely feedback and recommendations for improvement
                  to ensure that your mobile application is a success.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/manual-testing2.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default ManualTesting;
