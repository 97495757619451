const KeyServiceData = [
  {
    key: 1,
    title: "Web Development",
    link: "/web-development",
    desc: "At Technocrom, we specialize in creating dynamic web development solutions that help businesses showcase their best face to their target market. Our team works tirelessly to ensure that your website is not only aesthetically pleasing but also functional and user-friendly, providing a seamless experience across desktop and mobile devices.",
    image: require("../../assets/images/keyService/1.jpg"),
  },
  {
    key: 2,
    title: "Digital Testing",
    link: "/digital-testing",
    desc: "In today's fast-paced digital world, businesses are striving to keep up with the ever-growing demands of their customers. Digital technology is evolving rapidly and has brought significant changes to the way enterprises operate. Customers today want all services available to them at the click of a button, making it essential for businesses to provide high-quality digital applications that meet their needs.",
    image: require("../../assets/images/keyService/2.jpg"),
  },
  {
    key: 3,
    title: "Test Automation",
    link: "/test-automation",
    desc: "Testing is an essential part of software development, but it can be a time-consuming and costly process. This is particularly true for projects that undergo frequent changes to the code. Manual regression testing for such products can quickly become tedious and inefficient, especially when valuable human resources are allocated to this task.",
    image: require("../../assets/images/keyService/3.jpg"),
  },
  {
    key: 4,
    title: "Mobile Development",
    link: "/app-development",
    desc: "Technocrom offers comprehensive mobile application development services to cater to various mobility needs of enterprises, consumers, and industries. Our services cover a wide range of mobile app development platforms including native (iOS, Android, Windows, WinCE), hybrid, and web-based mobile applications. Our team of experts provides tailor-made services that strike a perfect balance between security, functionality, and user experience, as per the specific requirements of each client.",
    image: require("../../assets/images/keyService/4.jpg"),
  },
  {
    key: 5,
    title: "Security Testing",
    link: "/security-testing",
    desc: "The rise of cybercrimes has become a major concern for businesses worldwide. In 2021, it was reported that cybercrimes have increased by 600% globally, with hackers compromising the systems of approximately 67% of companies based in the United States. The World Economic Forum's Global Risks 2015 report states that 90% of companies worldwide recognize they are insufficiently prepared to protect themselves against cyber-attacks, which could cost the global economy over a trillion dollars annually.",
    image: require("../../assets/images/keyService/5.jpg"),
  },
  {
    key: 6,
    title: "Performance Testing",
    link: "/performance-testing",
    desc: "As technology continues to advance, the complexity of technology services also increases, and the reliability of an application becomes a crucial differentiator. At Technocrom, we understand the importance of robustness and reliability for an application, and we offer services that help enrich your products by thoroughly testing their performance. Our load and stress testing services ensure that your web and embedded applications can handle heavy traffic and perform well under stress.",
    image: require("../../assets/images/keyService/6.jpg"),
  },
  {
    key: 7,
    title: "Manual Testing",
    link: "/manual-testing",
    desc: "Manual testing is a crucial step in ensuring the quality of an application. At Technocrom SQA, we understand the importance of manual testing and offer a comprehensive range of services to meet the needs of our clients. Our process begins with a thorough analysis of the requirements to create a test plan that outlines the manual and automated testing needed for the application.",
    image: require("../../assets/images/keyService/7.jpg"),
  },
  {
    key: 8,
    title: "Test Advisory",
    link: "/test-advisory",
    desc: "The right QA strategy can help you improve the overall product quality and reduce release cycle time enabling faster time-to-market and controlling the rising QA costs. Technocrom’s Test advisory Services provide you with the specialist advice to achieve these objectives and help you move to the next maturity level in QA. We deliver custom solutions that address your current business needs and align with your future aspirations. Using the TMMI Based Assessment, Technocrom will assess your current test organization and map it to the TMMI model. Technocrom will help clients test organization for TMMI certification by identifying gaps and areas of improvement. Based on that, the roadmap will be defined for prioritized items to be implemented.",
    image: require("../../assets/images/keyService/8.jpg"),
  },
];

export default KeyServiceData;
