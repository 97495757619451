import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SingleWork from "./SingleWork";

const WorkWithUs = () => {
  return (
    <>
      <section className="section-padding">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-heading text-center">
                <h2>Work With Technocrom</h2>
                <p>
                  <i>
                    <b>
                      "Employees who feel valued and supported are more likely
                      to go above and beyond for their company."
                    </b>
                  </i>
                </p>
                <p>
                  At Technocrom, we prioritize the well-being of our employees
                  and strive to create a positive and supportive work
                  environment. We offer flexible work options, including remote
                  and hourly-based jobs, to ensure a healthy work-life balance.
                  We believe that physical and mental health is essential for
                  overall happiness and productivity, which is why we provide
                  our permanent employees with various facilities to support
                  their well-being.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <SingleWork />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WorkWithUs;
