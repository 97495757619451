import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import OurJourneyBar from "./OurJourneyBar";

const OurJourney = () => {
  return (
    <>
      <section
        className="twin-section reversed-twin mt-default"
        id="our-journey"
      >
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Our Journey</h2>
                <p>
                  Our CEO started Technocrom's journey alone in 2018, building a
                  team of highly experienced professionals who are dedicated to
                  providing top-quality QA and testing services. Today, we are
                  proud to be one of the most trusted organizations in the
                  industry, thanks to our unwavering commitment to excellence.
                </p>
                <p>
                  Despite our humble beginnings with just a handful of clients,
                  we have grown to work with some of the world's most prominent
                  companies. Our team's expertise covers a broad range of areas,
                  including:
                </p>

                <div className="link-area justify-content-start">
                  <Link to="/how-we-work" className="default-blue">
                    Explore More <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/company/journey.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>
      <section className="section-padding pt-0">
        <Container>
          <Row>
            <Col md={12}>
              <OurJourneyBar />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurJourney;
