import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const AdobeManager = () => {
  return (
    <>
      <InnerBanner title="Gaming Apps" />
      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Game App Development: Unleash Your Gaming Vision</h2>
                <p>
                  Are you passionate about creating captivating gaming
                  experiences? Look no further! At Technocrom, we specialize in
                  game app development, turning your creative vision into a
                  reality. With our team of skilled developers and designers, we
                  bring your game concepts to life, delivering immersive and
                  engaging experiences across platforms.
                </p>

                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/game1.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Why Choose TECHNOCROM for Game App Development?</h2>
                <p>
                  Expertise and Experience: Our team consists of experienced
                  game developers who are well-versed in various genres and
                  platforms. From casual mobile games to console experiences, we
                  have the expertise to tackle diverse projects.
                </p>

                <Row className="lifeAtTechno-boxes justify-content-center">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Customized Solutions:</b>
                        We understand that each game is unique, and we tailor
                        our development process to meet your specific
                        requirements. We work closely with you, from initial
                        concept to final launch, ensuring that your game stands
                        out from the crowd.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Cutting-Edge Technologies:</b> We stay up-to-date
                        with the latest trends and technologies in the gaming
                        industry. Leveraging tools such as Unity, Unreal Engine,
                        and other industry-leading platforms, we deliver
                        high-quality graphics, seamless gameplay, and engaging
                        user experiences.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Cross-Platform Development:</b> Reach a wider
                        audience with our cross-platform development
                        capabilities. Whether it's iOS, Android, PC, or
                        consoles, we ensure your game runs smoothly and
                        flawlessly on multiple devices, giving players the
                        freedom to enjoy your game wherever they are.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Collaborative Approach:</b> We believe in open
                        communication and collaboration throughout the
                        development process. We value your input and feedback,
                        working together to refine and enhance your game,
                        resulting in a final product that exceeds your
                        expectations.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/game2.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Our Game Development Process</h2>

                <Row className="lifeAtTechno-boxes justify-content-center">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Concept and Design:</b> We begin by understanding
                        your game concept, target audience, and desired gameplay
                        mechanics. Our team collaborates with you to create a
                        comprehensive game design document, outlining all
                        aspects of your game.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Development and Coding:</b> Once the design is
                        finalized, our skilled developers start coding the game,
                        implementing features, mechanics, and integrating
                        stunning visuals and sound effects.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Testing and Quality Assurance:</b> We conduct
                        rigorous testing and quality assurance to ensure your
                        game performs optimally on different devices. We
                        identify and resolve any bugs or issues, ensuring a
                        smooth and immersive gaming experience.
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        <b>Launch and Support:</b> We assist you in launching
                        your game on various app stores or platforms, providing
                        ongoing support and updates to address any issues and
                        introduce new features as needed.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/game3.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Let's Create Your Gaming Masterpiece</h2>
                <p>
                  Ready to turn your gaming vision into a reality? Partner with
                  Technocrom and let our game app development expertise bring
                  your ideas to life. We are passionate about creating
                  unforgettable gaming experiences that captivate and engage
                  players.
                </p>
                <p>
                  Get in touch with us today to discuss your game app
                  development needs. Together, we'll create a game that stands
                  out in the competitive gaming industry and keeps players
                  coming back for more.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/game4.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default AdobeManager;
