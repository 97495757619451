import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const Ecommerce = () => {
  return (
    <>
      <InnerBanner title="E-Commerce" />

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>E-Commerce</h2>
                <p>
                  Creating a secure B2B e-commerce website is crucial for
                  businesses looking to expand their reach globally.
                  Technocrom's expertise in creating robust storefront model
                  websites can help businesses establish a strong online
                  presence and streamline their e-commerce operations.
                </p>
                <p>
                  Some of the key features that Technocrom offers in their
                  e-commerce solutions include user account management,
                  inventory integration, product reviews management, payment
                  options management, and secure data transactions. These
                  features can help businesses provide a seamless and secure
                  shopping experience for their customers, which can lead to
                  increased customer loyalty and higher conversion rates.
                </p>
                <p>
                  In addition to website development, Technocrom also offers
                  ongoing website maintenance services to ensure that the
                  website runs smoothly and is up to date with the latest
                  security patches and software updates. This can help
                  businesses minimize downtime and ensure that their e-commerce
                  operations are running smoothly at all times.
                </p>
                <p>
                  If you're interested in discussing ideas and options for your
                  online store, Technocrom can provide expert guidance and
                  support throughout the development and maintenance process.
                  With their experience and expertise in B2B e-commerce,
                  Technocrom can help businesses achieve global outreach and
                  increase their revenue through their online presence.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/ecom1.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Our Contribution to your business</h2>
                <p>
                  Technocrom is your one stop solution for all your e-commerce,
                  marketing, standards compliant, support and maintenance
                  services and development needs!
                </p>

                <Row className="lifeAtTechno-boxes justify-content-center">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        All in one E-commerce and web marketing services,
                        including SEO and branding
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Customized solutions to entice your target audiences
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Web standards compliant development, ensuring secure,
                        user-centered solutions
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Affordable support and maintenance services for each
                        project
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Expert designers and developers working as a team to
                        bring about an all-round excellent output
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/ecom2.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Engage Technocrom For</h2>

                <Row className="lifeAtTechno-boxes justify-content-center mt-0">
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>E-Commerce web design and development</p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Configuration and customization of e-commerce platforms
                        to suit your business needs
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>Secure transactions with https protocol integration</p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Transaction support for credit/debit cards (Master and
                        Visa), PayPal, COD, and Skrill
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Clean and user-friendly website interface design to
                        encourage user engagement
                      </p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="why-techno-detail">
                      <p>
                        Effective Search Engine Optimization (SEO) to improve
                        your market outreach
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/expertise/ecom3.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default Ecommerce;
