import React from "react";

const DashBoard = () => {
  return (
    <>
      DashBoard
    </>
  );
};

export default DashBoard;
