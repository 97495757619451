import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SimpleAbout = () => {
  return (
    <>
      <section className="section-padding about-section">
        <div className="section-padding second-mob-section about-bg-area">
          <Container>
            <Row>
              <Col xxl={5} lg={6}>
                <div className="section-heading">
                  <h2>Let's Get Familiar</h2>
                  <p>
                    Founded in 2018, Technocrom is a global service provider
                    based in Chicago, Illinois. Over the years, we have served
                    multiple clients from different parts of the world,
                    including Fortune 500 companies. Our team of professionals
                    is unmatched in their experience and expertise in their
                    respective domains. As an independent service provider, we
                    offer Research and Development (R&D), including development
                    and quality assurance, as well as professional services. At
                    Technocrom, we prioritize our employees and workers'
                    well-being, ensuring that they enjoy their work and perform
                    at their best. Our remote and hourly-based jobs provide
                    work-life balance for our team members while working with
                    us.
                  </p>
                  <div className="link-area justify-content-start">
                    <Link to="/about-us" className="default-blue">
                      Search And Apply
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default SimpleAbout;
