import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";

const SimpleGoal = () => {
  return (
    <>
      <section className="section-padding ourGoal-section">
        <div className="section-padding second-mob-section about-bg-area ourGoal-bg-area">
          <Container>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="ourGoalArea">
                  <p>
                    At our company, we are dedicated to creating sustainable
                    value for our customers by disrupting the status quo and
                    implementing innovative ideas and technologies to meet their
                    QA, testing, and development needs. We remain
                    technology-agnostic, allowing us to concentrate on solving
                    our clients' unique requirements. Our primary focus is on
                    ensuring our customers' satisfaction, which drives our
                    success. We believe in surpassing their expectations, and
                    that belief powers our every action. Our team is committed
                    to delivering top-quality services that exceed our clients'
                    expectations, ensuring that they are delighted with the
                    results.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section-padding first-mob-sec text-center">
          <img src={require("../../assets/images/logo-shape.png")} alt="" />
          <div className="section-heading text-center mb-0">
            <h2>Lets Work Together</h2>
            <div className="link-area">
              <Link to="/contact" className="default-blue">
                Contact Us Now! <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          {/* <div className="scrollDownBtn"></div> */}
        </div>
      </section>
    </>
  );
};

export default SimpleGoal;
