import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const WebDevelopment = () => {
  return (
    <>
      <InnerBanner title="Web Development" />

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Dynamic Web Development Services</h2>
                <p>
                  At Technocrom, we specialize in creating dynamic web
                  development solutions that help businesses showcase their best
                  face to their target market. Our team works tirelessly to
                  ensure that your website is not only aesthetically pleasing
                  but also functional and user-friendly, providing a seamless
                  experience across desktop and mobile devices.
                </p>
                <p>
                  We understand that your website is an essential part of your
                  digital marketing strategy, and therefore we ensure that it
                  integrates seamlessly with other online channels, such as
                  social media, to maximize your online presence.
                </p>
                <p>
                  Our team pays close attention to every detail, from choosing
                  the right color scheme and fonts to optimizing navigation and
                  readability, to ensure that your website retains the user's
                  attention for longer periods. With Technocrom's web
                  development services, you can be confident that your website
                  will provide your users with an enjoyable experience while
                  achieving your business goals.
                </p>
                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/web-development.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Web Content and Content Writing</h2>
                <p>
                  At Technocrom, we believe that content is the key element in
                  effective communication. We understand that well-written
                  content can make all the difference in creating a strong brand
                  image and engaging your target audience. That's why we have
                  integrated content creation into our design process,
                  particularly for web design.
                </p>
                <p>
                  Before starting to write, we engage in an ideation phase where
                  we brainstorm ideas and discuss the approach to writing the
                  content. We consider key points such as the purpose of the
                  content, the tone, the target audience, and the messaging. By
                  doing so, we can develop winning articles that meet the needs
                  of our clients and their audiences.
                </p>
                <p>
                  Our content writers are skilled in understanding the buyer
                  persona, which is a critical aspect of effective content
                  writing. By understanding the audience's mindset, our writers
                  can translate their thoughts into words that resonate with
                  them. The result is content that not only informs but also
                  engages the audience, driving traffic and increasing brand
                  awareness.
                </p>
                <p>
                  At Technocrom, we believe that great content is a crucial
                  component of a successful digital marketing strategy. Whether
                  it's website copy, blog posts, or social media content, we
                  create content that is tailored to your brand's message and
                  tone. Our content writing services will help you connect with
                  your audience and achieve your marketing goals.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/web-development1.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>UI UX Design Process</h2>
                <p>
                  The design industry has undergone significant changes in the
                  last decade, with an increased focus on the systematic and
                  logical aspects of design. User Interface Design (UI) is now
                  the primary design process used for web interfaces, with a
                  focus on the surface look and feel of the overall design.
                  Additionally, User Experience Design (UX) has emerged as an
                  important aspect of the design process, covering the complete
                  experience of using a digital product such as a website,
                  mobile app, or catalogue. Combining UI and UX design provides
                  designers with a clear focus and direction to create products
                  that not only look great but also provide an engaging and
                  enjoyable user experience.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/web-development2.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Testing and QA</h2>
                <p>
                  We take great pride in ensuring that all of our web designs,
                  whether for websites or web applications, meet the highest
                  standards of quality. To achieve this, we have an independent
                  Quality Assurance (QA) department that thoroughly tests all
                  aspects of the design, including compatibility, usability,
                  accessibility, and technical functionalities. This
                  comprehensive approach to testing ensures that the end product
                  not only looks great but also provides a seamless user
                  experience.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/web-development3.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Web Development</h2>
                <p>
                  At Technocrom, we understand that each client has unique needs
                  and requirements, and we select development platforms
                  accordingly to ensure that the final product is tailored to
                  their needs. Our team of experienced web developers use a
                  range of coding languages, web tools, and platforms to convert
                  the web design into a fully-functional website.
                </p>
                <p>
                  We have expertise in a variety of open source platforms such
                  as WordPress, Drupal, Joomla, and DotNetNuke, and we select
                  the platform based on the client’s specific needs. Our team is
                  well-versed in these platforms and can ensure that the website
                  is developed efficiently and effectively.
                </p>
                <p>
                  Throughout the web development process, we maintain a focus on
                  functionality, performance, and user experience. We ensure
                  that the website is optimized for compatibility, usability,
                  accessibility, and technical functionalities, providing a
                  seamless and enjoyable user experience for visitors.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/web-development4.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section reversed-twin mt-default mb-default">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Support and Maintenance</h2>
                <p>
                  At Technocrom, we understand the importance of ongoing website
                  support and maintenance. We offer a range of services to help
                  ensure that your website remains up-to-date, secure, and
                  performing at its best. Our support and maintenance services
                  include website performance monitoring, issue tracking and
                  resolution, regular updates and backups, and website expansion
                  to accommodate your changing needs.
                </p>
                <p>
                  Our team of experts is available to address any issues that
                  may arise after your website launch. We are committed to
                  ensuring that your website continues to meet your expectations
                  and provide your users with an outstanding experience. Our
                  support and maintenance services are designed to keep your
                  website running smoothly and help you get the most out of your
                  investment.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/web-development5.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default WebDevelopment;
