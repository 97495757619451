import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../components/InnerBanner";

const PerformanceTesting = () => {
  return (
    <>
      <InnerBanner title="Performance Testing" />

      <section className="twin-section mt-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>In Today’s Technology-Driven World</h2>
                <p>
                  As technology continues to advance, the complexity of
                  technology services also increases, and the reliability of an
                  application becomes a crucial differentiator. At Technocrom,
                  we understand the importance of robustness and reliability for
                  an application, and we offer services that help enrich your
                  products by thoroughly testing their performance. Our load and
                  stress testing services ensure that your web and embedded
                  applications can handle heavy traffic and perform well under
                  stress.
                </p>
                <p>
                  Load testing involves simulating a heavy user load on an
                  application to measure its response time and determine its
                  maximum capacity. Stress testing involves pushing an
                  application beyond its limits to see how it responds under
                  extreme conditions. With our load and stress testing services,
                  we can help you identify performance bottlenecks, measure
                  response times, and determine the maximum capacity of your
                  applications.
                </p>
                <p>
                  Our team of experts has extensive experience in load and
                  stress testing, and we use the latest tools and technologies
                  to ensure that your applications are thoroughly tested for
                  performance. With our load and stress testing services, you
                  can rest assured that your applications will perform reliably
                  under heavy user loads and stressful conditions.
                </p>

                <div className="link-area justify-content-start">
                  <Link to="/contact" className="default-blue">
                    Contact Us <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/performance-testing.jpg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="section-padding">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-heading text-center mb-0">
                <h2>Benefits</h2>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center lifeAtTechno-boxes text-center">
            <Col md={6}>
              <div className="why-techno-detail">
                <h3>Standardization of applications</h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="why-techno-detail">
                <h3>Realistic evaluation of applications’ performance</h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="twin-section reversed-twin">
        <Container>
          <Row>
            <Col md={6} className="offset-md-6">
              <div className="section-heading section-padding">
                <h2>Performance Testing for Web Applications</h2>
                <p>
                  Performance testing for web applications involves conducting
                  load tests, stress tests, and soak tests. Load testing
                  services ensure that your web, server, and desktop
                  applications can handle an unforeseeable amount of load before
                  being released to the market. Soak tests are carried out by
                  keeping the application under load for an extended period of
                  time, while stress tests focus on testing the application's
                  behavior when it reaches its breaking point.
                </p>
                <p>
                  At our company, we excel in a variety of development tools
                  that help us respond quickly to user requirements with great
                  quality. Our testing services are designed to meet the
                  challenges inherent in such applications, including the
                  handling of large amounts of data and the need for significant
                  analytical power to interpret the data.
                </p>
                <p>
                  Our performance testing services are aimed at ensuring that
                  your web application performs optimally under various load
                  conditions, ensuring that it meets the required performance
                  and scalability standards. We work diligently to identify any
                  bottlenecks, vulnerabilities or performance issues in your web
                  application, which can be addressed proactively to minimize
                  any potential negative impact on your end-users.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/performance-testing1.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>

      <section className="twin-section mt-default mb-default">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-heading section-padding">
                <h2>Performance Testing for Embedded Applications</h2>
                <p>
                  Performance testing of embedded applications involves several
                  aspects, including identifying the maximum CPU usage and
                  maximum memory usage for standard compliance. At our company,
                  we offer performance testing services for embedded
                  applications to test their behavior under maximum limits.
                </p>
                <p>
                  Our performance testing services for embedded applications
                  include stress testing to identify where the application
                  breaks and to see if it responds gracefully after crossing the
                  threshold. We also conduct soak tests, which involve keeping
                  the application under load for longer periods to ensure its
                  reliability and stability under sustained usage.
                </p>
                <p>
                  We work diligently to identify any potential bottlenecks or
                  performance issues in your embedded application, ensuring that
                  it meets the required performance and scalability standards.
                  Our team uses a variety of development tools to help us
                  respond quickly to any performance issues, ensuring optimal
                  performance of your embedded application.
                </p>
                <p>
                  Through our performance testing services, we strive to ensure
                  that your embedded application performs reliably and
                  effectively, even under challenging conditions. Our goal is to
                  identify any potential issues early on in the testing process,
                  allowing us to address them proactively and minimize any
                  potential negative impact on your end-users.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          src={require("../../assets/images/services-inner/performance-testing2.jpeg")}
          alt=""
          className="twin-section-img"
        />
      </section>
    </>
  );
};

export default PerformanceTesting;
